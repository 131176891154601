import React, { Component } from 'react';
import Header from '../components/layout/header';
import Contactform from '../components/section/contactform';
import Footer from '../components/layout/footer';
import Googlemap from '../components/section/googlemap';
import SubHeader from '../components/layout/subheader';
import config from '../constant';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Image } from 'react-bootstrap';

class Clientele extends Component {

  
    // componentDidMount(){
    //     document.title = "Contact | Hotel Green County"
    //   }

    render(){
        return(
            <div>
                
             <HelmetProvider>
             <title>Our Clientele | BICads Digital - Website Development &amp; Digital Marketing</title>
	<link rel="canonical" href="https://bicads.com/contact/" />
   </HelmetProvider>
            <Header/>  
            <SubHeader  Title='Our Clientele' subtitle='Our Clientele' BkImg='/assets/images/bicads/Banner/Graphic-Designing.jpg'/> 

             {/* ------------------------- intro------------------------------------------------- */}

        <div className='  clientele  AboutUs py-5' >
                <Container className="     text-center">
                 <h4 class="pt-2">OUR CLIENTELE </h4>
                </Container>
                </div>

                <div className='ServiceHeading clientele  AboutUs py-5' style={{backgroundColor:'#e9f6fe'}}>

<Container className=' '>
<Row>
    <Col lg="12" className='text-center'>
        <h1 class="mainTextH1">SEO</h1>
    </Col>
</Row>
<Row className='pt-4'>
   <Col lg="2" xs="6">
        <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-1.jpg" />
    </Col>
    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-2.jpg" />

    </Col>
    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-3.jpg" />

    </Col>
    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-4.jpg" />

    </Col>

    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-5.jpg" />

    </Col>
    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-6.jpg" />

    </Col>
    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-7.jpg" />

    </Col>
    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-8.jpg" />

    </Col>

    
</Row>
</Container>

</div>

      
<Container className='py-5 clientele AboutUs'>
<Row>
    <Col lg="12" className='text-center'>
        <h1 class="mainTextH1">Social Media</h1>
    </Col>
</Row>
<Row className='pt-4'>
   <Col lg="2" xs="6">
        <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-7.jpg" />
    </Col>
    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-9.jpg" />

    </Col>
    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-4.jpg" />

    </Col>
    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-8.jpg" />

    </Col>

    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-10.jpg" />

    </Col>
    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-11.jpg" />

    </Col>
    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-5.jpg" />

    </Col>
    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-12.jpg" />

    </Col>

    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-13.jpg" />

    </Col>
    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-14.jpg" />

    </Col>

    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-25.jpg" />

    </Col>
</Row>
</Container>
 


<div className='ServiceHeading clientele  AboutUs pt-5' style={{backgroundColor:'#e9f6fe'}}>

<Container className='pb-5'>
<Row>
    <Col lg="12" className='text-center'>
        <h1 class="mainTextH1">Paid Ads
</h1>
    </Col>
</Row>
<Row className='pt-4'>
   <Col lg="2" xs="6">
        <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-15.jpg" />
    </Col>
    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-16.jpg" />

    </Col>
    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-14.jpg" />

    </Col>
    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-17.jpg" />

    </Col>

    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-18.jpg" />

    </Col>
    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-10.jpg" />

    </Col>
    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-11.jpg" />

    </Col>
    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-13.jpg" />

    </Col>

    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-9.jpg" />

    </Col>
    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-8.jpg" />

    </Col>

    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-25.jpg" />

    </Col>
    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-26.jpg" />

    </Col>
    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-27.jpg" />

    </Col>
    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-28.jpg" />

    </Col>
</Row>
</Container>
</div>



 
<Container className='py-5 clientele AboutUs'>
<Row>
    <Col lg="12" className='text-center'>
        <h1 class="mainTextH1">Websites
</h1>
    </Col>
</Row>
<Row className='pt-4'>
   <Col lg="2" xs="6">
        <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-5.jpg" />
    </Col>
    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-19.jpg" />

    </Col>
    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-20.jpg" />

    </Col>

    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-12.jpg" />

    </Col>
    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-21.jpg" />

    </Col>

    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-18.jpg" />

    </Col>
    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-2.jpg" />

    </Col>
    <Col lg="2" xs="6">
                <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-6.jpg" />

    </Col>
    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-10.jpg" />

    </Col>

    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-11.jpg" />

    </Col>
    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-23.jpg" />

    </Col>
    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-24.jpg" />

    </Col>

    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-22.jpg" />

    </Col>
    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-4.jpg" />

    </Col>

    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-25.jpg" />

    </Col>
    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-26.jpg" />

    </Col>
    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-27.jpg" />

    </Col>
    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-28.jpg" />

    </Col>
    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-29.jpg" />

    </Col>
    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-30.jpg" />

    </Col>
    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-31.jpg" />

    </Col>
    <Col lg="2" xs="6">
                 <Image fluid src="assets/images/bicads-images/client/more/Bicads client logo-32.jpg" />

    </Col>
</Row>
</Container>
     
             <Footer/>
            </div>
        )
    }
}

export default Clientele;