import React, { Component , useEffect } from 'react';
import Header from '../components/layout/header';
import Contactform from '../components/section/contactform';
import Footer from '../components/layout/footer';
import Googlemap from '../components/section/googlemap';
import SubHeader from '../components/layout/subheader';
import config from '../constant';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Container  from 'react-bootstrap/Container';
import { NavLink } from 'react-router-dom';
import Col from 'react-bootstrap/Col';

 
    function ThankYou() {

        useEffect(() => {
            // Create a script element
               // Add Google Analytics (gtag.js) script
    const gtagScript = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-6MJ4PDR1B2';
    document.head.appendChild(gtagScript);

    const gtagInitScript = document.createElement('script');
    gtagInitScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-6MJ4PDR1B2');
    `;
    document.head.appendChild(gtagInitScript);

    // Add Google Tag Manager (GTM) script
    const gtmScript = document.createElement('script');
    gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-54G3X6MJ');
    `;
    document.head.appendChild(gtmScript);

            const script = document.createElement('script');
            script.innerHTML = `
              gtag('event', 'conversion', {
                'send_to': 'AW-10800844154/IX7gCMOL2fMZEPqan54o',
                'value': 1.0,
                'currency': 'INR'
              });
            `;
            // Append the script to the head or body
            document.body.appendChild(script);
        
            // Clean up the script when the component unmounts
            return () => {
              document.body.removeChild(script);
              document.head.removeChild(gtagScript);
              document.head.removeChild(gtagInitScript);
              document.head.removeChild(gtmScript);
            };
          }, []);


         return(
            <div>
                
             
             {/* ------------------------- intro------------------------------------------------- */}

        <div className='ServiceHeading  pt-5 '  style={{marginTop:"100px"}}>
                <Container className=" mt-5  text-center">
                <h1 className='mainTextH1 mt-5'>Thank You</h1>

            <h2 className='px-5 '>We will contact you very soon!!</h2>

            
            <Col className='text-center pt-5'>
            <NavLink  to="/"   activeclassname="active"><button type="button" class="btn btn-primary btn-md  draw ">Go Back</button></NavLink></Col>
                      
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}
             {/* <CardSec/>     */}
             
            </div>
        )
    
}

export default ThankYou;